import request from '@/utils/request'

export const reqSendCode = (params) => {
  return request({
    baseURL: 'https://lw.yifanghy.com/',
    // baseURL: 'http://10.10.243.154:80',
    url: '/openApi/verifyPhoneLostWord',
    method: 'GET',
    params
  })
}
export const reqSubmit = (data) => {
  return request({
    baseURL: 'https://lw.yifanghy.com/',
    // baseURL: 'http://10.10.243.154:80',
    url: '/openApi/reservationLostWord',
    method: 'POST',
    data
  })
}
